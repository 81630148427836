<script lang="ts" setup>
  import { getMoviesCollections } from '~/services/modules/movies.service';
  import CenteredSlider from '~/components/sliders/centered/CenteredSlider.vue';
  import CenteredSkeleton from '~/components/sliders/centered/CenteredSkeleton.vue';
  import type { TBrowseTitle } from '~/types/common';

  const props = defineProps<TBrowseTitle>();
  const { data: collections, isFetching, suspense } = getMoviesCollections();
  const { homeCollectionsInitialIndex, browseCollectionsInitialIndex } = storeToRefs(useSliderStore());
  const { checkIsNavigatedBackOrFront } = useSliderStore();

  const initialSliderIndex = computed({
    get() {
      return props.isCatalog ? browseCollectionsInitialIndex.value : homeCollectionsInitialIndex.value;
    },
    set(newValue) {
      if (props.isCatalog) {
        browseCollectionsInitialIndex.value = newValue;
      } else {
        homeCollectionsInitialIndex.value = newValue;
      }
    },
  });

  if (!checkIsNavigatedBackOrFront()) {
    initialSliderIndex.value = 0;
  }

  onServerPrefetch(async () => await suspense());

  const beforeSliderDestroy = (swiper) => {
    initialSliderIndex.value = swiper.realIndex;
  };
</script>

<template>
  <v-wrapper
    section
    title="Подборки"
    class="movies full-width"
    :use-low-priority-title="isCatalog"
    :link="{ name: 'Смотреть все', route: { name: 'collections' } }"
  >
    <div class="popular__slider breakout">
      <centered-slider
        v-if="collections"
        :items="collections ?? []"
        :initial-slide-index="initialSliderIndex"
        @before-destroy="beforeSliderDestroy"
      />
      <centered-skeleton v-else />
    </div>
  </v-wrapper>
</template>

<style lang="scss" scoped>
  .movies {
    padding: 56px 0;
    @media (max-width: $retina) {
      padding: 24px 0;
    }
  }
</style>
